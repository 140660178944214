*,
*:before,
*:after {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://themes.googleusercontent.com/static/fonts/opensans/v6/DXI1ORHCpsQm3Vp6mXoaTaRDOzjiPcYnFooOUGCOsRk.woff)
      format("woff");
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: "UOLText";
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background: #000;
  scroll-behavior: smooth;
}

#root {
  background-color: #202020;
  overflow: hidden;

  strong {
    font-family: "UOLTextBold", Arial, Helvetica, sans-serif;
    font-weight: normal;
  }
}

.div-pad {
  padding-left: 47px;
  padding-right: 47px;

  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.btn {
  font-size: 16px;
  color: #000000;
  padding: 10px 20px;
  background: linear-gradient(to right, #ef7b30, #f38d27, #f6a01d);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    color: #000000;
    opacity: 0.6;
  }

  &.btn-primary {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
}

.ico {
  display: inline-block;
  font-size: 9px;
  font-weight: bold;
  margin-left: 6px;
  color: #ffffff;
  width: 19px;
  height: 19px;
  border-radius: 2px;
  padding: 5px 0;
  text-align: center;
  text-transform: uppercase;
  line-height: 10px;
  vertical-align: text-bottom;

  &.ico-l,
  &.ico-Al,
  &.ico-al {
    background-color: #0a7c30;
  }

  &.ico-10,
  &.ico-A10,
  &.ico-a10 {
    background-color: #0e55a4;
  }

  &.ico-12,
  &.ico-A12,
  &.ico-a12 {
    background-color: #ffbc2c;
  }

  &.ico-14,
  &.ico-A14,
  &.ico-a14 {
    background-color: #ff652c;
  }

  &.ico-16,
  &.ico-A16,
  &.ico-a16 {
    background-color: #ec1a23;
  }

  &.ico-18,
  &.ico-A18,
  &.ico-a18 {
    background-color: #202020;
  }
}

.loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: #000;

  img {
    display: block;
    margin: auto;
    padding: 250px 0;
  }
}

.live-circle {
  display: inline-block;
  background: #cc2b1e;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 3px;
}

.owl-carousel {
  .owl-nav {
    @media (max-width: 768px) {
      display: none;
    }

    [class*="owl-"] {
      position: absolute;
      top: calc(145.5px - 19px);
      font-size: 14px;
      color: white;
      opacity: 1;
      width: 32px;
      height: 32px;
      border-radius: 20px;
      line-height: 24px;
      text-align: center;
      background: #000;

      &:hover {
        background: hsla(0, 0%, 100%, 0.8);
        color: #000;
      }
    }

    .owl-prev {
      left: -45px;
      &::before {
        background: url("../images/arrow_left.svg") center no-repeat;
        background-size: cover;
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 2px;
        position: relative;
        top: 7px;
      }
      &:hover {
        &::before {
          background: url("../images/arrow_left_black.svg") center no-repeat;
        }
      }
    }

    .owl-next {
      right: 0;
      &::before {
        background: url("../images/arrow_right.svg") center no-repeat;
        background-size: cover;
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        position: relative;
        top: 7px;
      }
      &:hover {
        &::before {
          background: url("../images/arrow_right_black.svg") center no-repeat;
        }
      }
    }
  }

  .thumbnails {
    display: block;
    width: 201px;
    height: 291px;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    border-radius: 4px;

    button {
      background: none;
    }

    &:hover {
      .details-hover {
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }

      figure {
        background-color: #000;
        border-radius: 4px;

        img {
          -webkit-opacity: 0.25 !important;
          -moz-opacity: 0.25 !important;
          opacity: 0.25 !important;
          transition: opacity 0.5s ease-in-out;
          border-radius: 4px;
        }
      }
    }

    .lbl-rent {
      z-index: 3;
      top: 10px;
      left: 130px;
      position: absolute;
      color: #fff;
      font-family: "UOLTextBold", Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      padding: 6px 7px 4px 8px;
      border-radius: 14px;
      text-align: center;
      background-color: #f7a11d;
      opacity: 1;
      font-size: 11px;
      line-height: 1;
    }

    .details-hover {
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
      z-index: 2;

      .content {
        position: absolute;
        bottom: 16px;
        left: 10px;
        right: 20px;

        .watch-btn {
          display: inline-block;
          position: relative;
          text-transform: none;
          border-radius: 4px;
          background-color: #ffffff;
          font-size: 13px;
          font-family: "UOLTextBold", Arial, Helvetica, sans-serif;
          font-weight: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: #202020 !important;
          padding: 6px 8px 4px 21px;
          text-decoration: none;
          z-index: 1;

          &:before {
            content: "";
            display: inline-block;
            border-style: solid;
            border-width: 6px 0 6px 10px;
            border-radius: 3px;
            border-color: transparent transparent transparent #202020;
            position: absolute;
            top: 6px;
            left: 8px;
          }
        }

        .title {
          margin-top: 16px;
          margin-bottom: 4px;
          font-size: 14px;
          font-family: "UOLTextBold", Arial, Helvetica, sans-serif;
          font-weight: normal;
          line-height: 1.21;
          text-align: left;
          color: #fff !important;
        }

        .description {
          font-size: 14px;
          font-weight: normal;
          line-height: 1.14;
          text-align: left;
          color: #fff !important;
        }
      }
    }

    figure {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}
